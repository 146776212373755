<template>
    <v-expansion-panel class="panel-purchase-order-line-items">
        <v-expansion-panel-header>
            <h2>Line Items</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row class="button-row">
                <!-- Move Button -->
                <ButtonMovePurchaseOrderLines
                    :disabled="!canMoveItems || disabled"
                    :selectedItems="table.selected"
                    @success="modalMoveItemsSuccessHandler()"
                />
                <!-- Export Button -->
                <ExportPurchaseOrderLines
                    :disabled="disabled"
                />
                <!-- Import Button -->
                <ImportPurchaseOrderLines
                    :disabled="disabled"
                />
                <!-- Remove Button -->
                <ButtonRemovePurchaseOrderItems
                    :disabled="!canMoveItems"
                    :selectedItems="table.selected"
                    @confirm="modalRemoveItemsConfirmHandler()"
                />
                <ToggleEANDelayed
                    :disabled="disabled"
                />
            </v-row>
            <br/>
            <TableBaseFilterable
                :filter.sync="table.filter"
                :headers="tableHeaders"
                :itemClass="getRowClass"
                :items="modifiablePurchaseOrderLineItemList"
                :selected.sync="table.selected"
                :showSelect="!disabled"
                filterLabel="Filter Purchase Order Lines..."
                noDataText="No Purchase Order Lines"
                sortBy="titleDescription"
            >
                <template #activator>
                    <v-btn
                        :disabled="currentPurchaseOrderCannotHaveLineItemsAdded || disabled"
                        class="activator-button"
                        outlined
                        @click="addButtonHandler()"
                    >
                        Add
                        <v-icon right>mdi-plus</v-icon>
                    </v-btn>
                </template>

                <!-- Conflict -->
                <template #[`item.conflict`]="{ item }">
                    <v-btn
                        v-if="purchaseOrderLineItemIsDuplicated( item )"
                        aria-label="warning"
                        color="red"
                        icon
                    >
                        <v-icon>mdi-alert</v-icon>
                    </v-btn>
                </template>

                <!-- Condition -->
                <template #[`item.condition`]="{ item }">
                    <DropdownCondition
                        :class="{ 'required-input': item.condition === '' }"
                        :disabled="disabled"
                        :value="item.condition"
                        @input="( value ) => conditionHandler( item, value )"
                    />
                </template>

                <!-- Product Code -->
                <template #[`item.productCode`]="{ item }">
                    <v-text-field
                        v-if="item.frontendIsNewLine"
                        :disabled="disabled"
                        :value="item.productCode"
                        class="text-field"
                        dense
                        hideDetails
                        outlined
                        @input="( value ) => productCodeHandler( item, value )"
                    />
                    <p v-else>{{ item.productCode }}</p>
                </template>

                <!-- Title -->
                <template #[`item.titleDescription`]="{ item }">
                    <p v-if="item.titleDescription === ''">( Generated )</p>
                    <p v-else>{{ item.titleDescription }}</p>
                </template>

                <!-- Quantity -->
                <template #[`item.requestedQuantity`]="{ item }">
                    <v-text-field
                        :class="{
                            'warning-input': item.requestedQuantity === 0,
                            'illegal-change': hasIllegalChange( item, 'Quantity' ),
                        }"
                        :disabled="disabled"
                        :value="item.requestedQuantity"
                        class="text-field input-quantity"
                        dense
                        hideDetails
                        min="0"
                        outlined
                        prependInnerIcon="mdi-pound"
                        type="number"
                        @input="( value ) => quantityHandler( item, value )"
                    />
                </template>

                <!-- Price -->
                <template #[`item.requestedPrice`]="{ item }">
                    <InputCurrency
                        :class="{ 'illegal-change': hasIllegalChange( item, 'Price' ) }"
                        :disabled="disabled"
                        :value="item.requestedPrice"
                        @input="( value ) => priceHandler( item, value )"
                    />
                </template>

                <!-- Expected Receive Date -->
                <template #[`item.expectedReceiveDate`]="{ item }">
                    <InputDatePicker
                        label="Expected Receive Date"
                        :disabled="disabled"
                        :min="new Date().toISOString().substring(0, 10)"
                        :date.sync="item.expectedReceiveDate"
                        outlined
                        @update:date="(value) => expectedReceiveDateHandler(item, value)"
                    />
                </template>

                <!-- Backordered / EAN Delayed -->
                <template #[`item.backordered`]="{ item }">
                    <Checkbox
                        :dark="false"
                        :disabled="disabled"
                        :value="item.backordered"
                        color="primary"
                        @input="( value ) => backorderedHandler( item, value )"
                    />
                </template>

                <!-- Note -->
                <template #[`item.note`]="{ item }">
                    <v-textarea
                        :value="item.note"
                        :readonly="disabled"
                        class="text-field"
                        dense
                        hideDetails
                        outlined
                        placeholder="PO Line Note"
                        rows="1"
                        @input="( value ) => noteHandler( item, value )"
                    />
                </template>

                <!-- Remove -->
                <template #[`item.remove`]="{ item }">
                    <v-btn
                        :disabled="disabled"
                        aria-label="delete"
                        icon
                        @click="removeButtonHandler( item )"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </TableBaseFilterable>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import ValidationUtil from '@/utils/ValidationUtil';

// Components
import Checkbox from '@/components/base/Checkbox';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import InputCurrency from '@/components/base/inputs/InputCurrency';
import DropdownCondition from '@/views/common/dropdowns/DropdownCondition';
import InputDatePicker from '@/components/InputDatePicker';
import ButtonMovePurchaseOrderLines from './panelPurchaseOrderLineItems/ButtonMovePurchaseOrderLines';
import ExportPurchaseOrderLines from './panelPurchaseOrderLineItems/ExportPurchaseOrderLines';
import ImportPurchaseOrderLines from './panelPurchaseOrderLineItems/ImportPurchaseOrderLines';
import ButtonRemovePurchaseOrderItems from './panelPurchaseOrderLineItems/ButtonRemovePurchaseOrderItems';
import ToggleEANDelayed from './panelPurchaseOrderLineItems/ToggleEANDelayed';

export default {
    name: 'PanelPurchaseOrderLinesItems',
    components: {
        InputDatePicker,
        Checkbox,
        TableBaseFilterable,
        InputCurrency,
        DropdownCondition,
        ButtonMovePurchaseOrderLines,
        ExportPurchaseOrderLines,
        ImportPurchaseOrderLines,
        ButtonRemovePurchaseOrderItems,
        ToggleEANDelayed,
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            rules: {
                required: ValidationUtil.required,
                requiredObject: ValidationUtil.requiredObject,
                requiredArray: ValidationUtil.requiredArray,
            },
            table: {
                filter: '',
                selected: [], // selected purchase order line items
            },
        };
    },
    computed: {
        ...mapGetters( {
            currentPurchaseOrderHasDuplicateItems: 'PurchaseOrder/Detail/currentPurchaseOrderHasDuplicateItems',
            currentPurchaseOrderHasItems: 'PurchaseOrder/Detail/currentPurchaseOrderHasItems',
            getCurrentPurchaseOrderLineItemIndex: 'PurchaseOrder/Detail/getCurrentPurchaseOrderLineItemIndex',
            modifiablePurchaseOrderLineItemList: 'PurchaseOrder/Detail/modifiablePurchaseOrderLineItemList',
            promptableLineItemChanges: 'PurchaseOrder/Detail/promptableLineItemChanges',
            currentPurchaseOrderCannotHaveLineItemsAdded: 'PurchaseOrder/Detail/currentPurchaseOrderCannotHaveLineItemsAdded',
        } ),
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
            inFlight: ( state ) => state.PurchaseOrder.inFlight,
        } ),

        /** @returns { Boolean } */
        purchaseOrderIsDraftStatus() {
            return this.currentPurchaseOrder.status === 'DRAFT';
        },
        /** @returns { Boolean } */
        hasSelectedItems() {
            return this.table.selected.length > 0;
        },
        /** @returns { Boolean } */
        canMoveItems() {
            return this.hasSelectedItems && this.purchaseOrderIsDraftStatus;
        },

        tableHeaders() {
            const headers = [
                {
                    text: 'Condition',
                    value: 'condition',
                    width: 150,
                },
                {
                    text: 'EAN',
                    value: 'productCode',
                },
                {
                    text: 'Title',
                    value: 'titleDescription',
                },
                {
                    text: 'Quantity',
                    value: 'requestedQuantity',
                    align: 'end',
                    width: 150,
                },
                {
                    text: 'Price',
                    value: 'requestedPrice',
                    align: 'end',
                    width: 125,
                },
                {
                    text: 'Expected Receive Date',
                    value: 'expectedReceiveDate',
                    width: 150,
                },
                {
                    text: 'EAN Delayed',
                    value: 'backordered',
                },
                {
                    text: 'Notes',
                    value: 'note',
                    width: 300,
                },
                {
                    text: 'Remove',
                    value: 'remove',
                    align: 'end',
                    sortable: false,
                    width: 100,
                },
            ];

            if ( this.currentPurchaseOrderHasDuplicateItems ) {
                headers.unshift( {
                    text: 'Conflict',
                    value: 'conflict',
                    sortable: false,
                } );
                return headers;
            }
            return headers;
        },
    },
    watch: {
        inFlight() {
            // Clear selected items after the modify call has completed
            if ( this.inFlight === false ) {
                this.table.selected = [];
            }
        },
    },
    methods: {
        ...mapActions( {
            updateCurrentPurchaseOrderLineItemProperty: 'PurchaseOrder/Detail/updateCurrentPurchaseOrderLineItemProperty',
        } ),

        /**
         * Returns true if there is more than one purchaseOrderLineItem that passes the following filter condition
         * @param { import('@/typedefs/purchaseOrderItem').purchaseOrderItem } purchaseOrderLineItem
         * @returns { Boolean }
         */
        purchaseOrderLineItemIsDuplicated( { condition, productCode, toBeDeleted } ) {
            return this.currentPurchaseOrderHasItems
                && this.currentPurchaseOrder.purchaseOrderItemDTOList
                    .filter( ( item ) => (
                        ( item.condition === condition )
                        && ( item.productCode === productCode )
                        && ( item.toBeDeleted === toBeDeleted )
                    ) )
                    .length > 1;
        },

        /** @returns { Boolean } */
        requiredFieldIsBlank( { productCode, requestedPrice } ) {
            return productCode === ''
                || requestedPrice === '';
        },

        /** @returns { String } */
        getRowClass( purchaseOrderLineItem ) {
            return this.requiredFieldIsBlank( purchaseOrderLineItem ) || this.purchaseOrderLineItemIsDuplicated( purchaseOrderLineItem )
                ? 'warning-background'
                : '';
        },
        /** @returns { Boolean } */
        hasIllegalChange( purchaseOrderLineItem, type ) {
            return this.promptableLineItemChanges
                .filter( ( promptableChange ) => (
                    promptableChange.productCode === purchaseOrderLineItem.productCode
                    && promptableChange.condition === purchaseOrderLineItem.condition
                    && promptableChange.type === type
                ) )
                .length > 0;
        },

        removeItemFromSelectedItems( selectedPurchaseOrderItem ) {
            this.table.selected = this.table.selected.filter( ( item ) => item.frontendKey !== selectedPurchaseOrderItem.frontendKey );
        },
        modalRemoveItemsConfirmHandler() {
            this.table.selected.forEach( ( item ) => {
                this.removeButtonHandler( item );
            } );
        },
        modalMoveItemsSuccessHandler() {
            this.table.selected = [];
        },

        addButtonHandler() {
            this.$store.commit( 'PurchaseOrder/Detail/ADD_CURRENT_PURCHASE_ORDER_LINE_ITEM' );
        },
        conditionHandler( { frontendKey }, value ) {
            this.updateCurrentPurchaseOrderLineItemProperty( {
                frontendKey,
                property: 'condition',
                value,
            } );
        },
        productCodeHandler( { frontendKey }, value ) {
            this.updateCurrentPurchaseOrderLineItemProperty( {
                frontendKey,
                property: 'productCode',
                value: FormatUtil.sanitizeIsbn( value.toString() ),
            } );
        },
        quantityHandler( { frontendKey }, value ) {
            this.updateCurrentPurchaseOrderLineItemProperty( {
                frontendKey,
                property: 'requestedQuantity',
                value,
            } );
        },
        priceHandler( { frontendKey }, value ) {
            this.updateCurrentPurchaseOrderLineItemProperty( {
                frontendKey,
                property: 'requestedPrice',
                value,
            } );
        },
        expectedReceiveDateHandler( { frontendKey }, value ) {
            this.updateCurrentPurchaseOrderLineItemProperty( {
                frontendKey,
                property: 'expectedReceiveDate',
                value: value === ''
                    ? null
                    : value,
            } );
        },
        backorderedHandler( { frontendKey }, value ) {
            this.updateCurrentPurchaseOrderLineItemProperty( {
                frontendKey,
                property: 'backordered',
                value: !!value,
            } );
        },
        noteHandler( { frontendKey }, value ) {
            this.updateCurrentPurchaseOrderLineItemProperty( {
                frontendKey,
                property: 'note',
                value,
            } );
        },
        removeButtonHandler( purchaseOrderLineItem ) {
            this.removeItemFromSelectedItems( purchaseOrderLineItem );
            this.$store.commit( 'PurchaseOrder/Detail/REMOVE_CURRENT_PURCHASE_ORDER_LINE_ITEM', {
                lineItemIndex: this.getCurrentPurchaseOrderLineItemIndex( purchaseOrderLineItem.frontendKey ),
            } );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/purchaseorder.scss";

::v-deep .v-data-table__wrapper {
    // Allows the date picker pop up to overflow past the edge of the table.
    // https://stackoverflow.com/a/39554003
    // DO NOT SET A HEIGHT OR MAX HEIGHT ON THIS TABLE
    padding-bottom: 20rem;
    margin-bottom: -20rem;
}

.panel-purchase-order-line-items {
    .button-row {
        margin-top: .5rem;
        margin-left: 1rem;

        > * {
            margin-right: 1rem;
        }
    }

    .activator-button {
        margin-left: 1.5rem;
    }

    .warning-background {
        background-color: $po-line-item-warning-bg;
    }

    .illegal-change {
        border: 3px solid $po-illegal-change-border;
    }

    .input-quantity, .v-input .v-icon {
        font-size: 0.75rem;
    }

    .checkbox-backordered {
        float: right;
    }
}
</style>
