<template>
    <div class="toggle-all-ean-delayed">
        <label>All EAN(s) Delayed</label>
        <Checkbox
            class="toggle-all-ean-delayed__checkbox"
            :dark="false"
            :value="allLineItemsBackordered"
            :indeterminate="someLineItemsBackordered && !allLineItemsBackordered"
            @input="toggleLineItemsBackordered"
        />
    </div>
</template>
<script>
// Vuex
import { mapActions, mapGetters, mapState } from 'vuex';

// Components
import Checkbox from '@/components/base/Checkbox';

export default {
    name: 'CheckboxToggleAllEANDelayed',
    components: {
        Checkbox,
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
        } ),
        ...mapGetters( {
            allLineItemsBackordered: 'PurchaseOrder/Detail/allLineItemsBackordered',
            someLineItemsBackordered: 'PurchaseOrder/Detail/someLineItemsBackordered',
        } ),
    },
    methods: {
        ...mapActions( {
            toggleLineItemsBackordered: 'PurchaseOrder/Detail/toggleLineItemsBackordered',
        } ),
    },
};
</script>
<style scoped lang="scss">
.toggle-all-ean-delayed {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-bottom: 1px solid gainsboro;
    &__checkbox {
        margin-left: 0.5rem;
        margin-top: -0.25rem;
    }
}
</style>
