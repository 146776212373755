<template>
    <div>
        <!-- Read-Only status change Modal -->
        <ModalNotifyOnlyStatusCanChange
            v-model="showChangesToReadOnlyStatusChangeModal"
            :saveButtonText="modalSaveButtonText"
            :previousStatus="referencePurchaseOrder ? referencePurchaseOrder.status : ''"
            :statusHasChanged="hasCurrentPurchaseOrderStatusChanged"
            @save="savePurchaseOrder( getModifiedPurchaseOrderStatus() )"
            @saveAndNext="saveAndGoToNextPurchaseOrder( getModifiedPurchaseOrderStatus() )"
        />

        <!-- Reconciled, Received, or Submitted Status Line Item Modification Confirmation Modal -->
        <ModalConfirmLineItemChanges
            v-model="showPromptableLineItemsModal"
            :saveButtonText="modalSaveButtonText"
            @save="savePurchaseOrder( modificationsWithoutNewLines )"
            @saveAndNext="saveAndGoToNextPurchaseOrder( modificationsWithoutNewLines )"
        />

        <!-- Reconciled, Received, or Submitted Status Line item addition Warning Modal -->
        <ModalCannotAddLineItems
            v-model="showLineItemsCannotBeAddedModal"
        />

        <!-- PO Detail Info -->
        <PurchaseOrderDetail
            v-if="hasCurrentPurchaseOrder"
        />

        <!-- Page Footer Bar -->
        <ThePageFooterBar class="po-detail-footer">

            <!-- Status -->
            <div class="po-status">
                <label for="dropdown-po-status" class="po-status__label">Status:</label>
                <TheDropdownPurchaseOrderStatus id="dropdown-po-status"/>
            </div>

             <!-- Save -->
            <div class="button-group">
                <v-btn
                    :loading="inFlight"
                    :disabled="actionIsBlocked || !currentPurchaseOrderHasModifications"
                    :color="( mustPromptWithModalBeforeSave ? 'warning' : 'primary' )"
                    @click="validateThenSave( savePurchaseOrder, modalSaveButtonTypes.save )"
                >
                    Save
                </v-btn>

                <!-- Save and Next -->
                <v-btn
                    :loading="inFlight"
                    :disabled="actionIsBlocked"
                    :color="( mustPromptWithModalBeforeSave ? 'warning' : 'primary' )"
                    @click="validateThenSave( saveAndGoToNextPurchaseOrder, modalSaveButtonTypes.saveAndNext )"
                >
                    Save & Next
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </div>
        </ThePageFooterBar>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapState } from 'vuex';

// Components
import ThePageFooterBar from '@/components/ThePageFooterBar';

import TheDropdownPurchaseOrderStatus from './TheDropdownPurchaseOrderStatus';
import ModalNotifyOnlyStatusCanChange from './detailView/ModalNotifyOnlyStatusCanChange';
import ModalConfirmLineItemChanges from './detailView/ModalConfirmLineItemChanges';
import ModalCannotAddLineItems from './detailView/ModalCannotAddLineItems';
import PurchaseOrderDetail from './detailView/PurchaseOrderDetail';

export default {
    name: 'DetailView',
    components: {
        ThePageFooterBar,
        TheDropdownPurchaseOrderStatus,
        ModalNotifyOnlyStatusCanChange,
        ModalConfirmLineItemChanges,
        ModalCannotAddLineItems,
        PurchaseOrderDetail,
    },
    data() {
        return {
            inFlight: false,
            showChangesToReadOnlyStatusChangeModal: false,
            showPromptableLineItemsModal: false,
            showLineItemsCannotBeAddedModal: false,
            modalSaveButtonText: '',
            modalSaveButtonTypes: {
                save: 'Save',
                saveAndNext: 'Save & Next',
            },
        };
    },
    computed: {
        ...mapGetters( {
            hasCurrentPurchaseOrder: 'PurchaseOrder/Detail/hasCurrentPurchaseOrder',
            currentPurchaseOrderModifications: 'PurchaseOrder/Detail/modifications',
            currentPurchaseOrderHasModifications: 'PurchaseOrder/Detail/hasModifications',
            currentPurchaseOrderItemsAreValid: 'PurchaseOrder/Detail/currentPurchaseOrderItemsAreValid',
            currentPurchaseOrderHasDuplicateItems: 'PurchaseOrder/Detail/currentPurchaseOrderHasDuplicateItems',
            hasCurrentPurchaseOrderStatusChanged: 'PurchaseOrder/Detail/hasCurrentPurchaseOrderStatusChanged',
            modificationsWithoutNewLines: 'PurchaseOrder/Detail/modificationsWithoutNewLines',
            mustPromptToAllowLineItemChangesToBeSaved: 'PurchaseOrder/Detail/mustPromptToAllowLineItemChangesToBeSaved',
            mustPromptToAllowReadOnlyStatusChanges: 'PurchaseOrder/Detail/mustPromptToAllowReadOnlyStatusChanges',
            mustPromptWithModalBeforeSave: 'PurchaseOrder/Detail/mustPromptWithModalBeforeSave',
            referencePurchaseOrder: 'PurchaseOrder/Detail/referencePurchaseOrder',
            mustPromptToShowLineItemsCannotBeModified: 'PurchaseOrder/Detail/mustPromptToShowLineItemsCannotBeModified',
            currentPurchaseOrderCannotHaveLineItemsAdded: 'PurchaseOrder/Detail/currentPurchaseOrderCannotHaveLineItemsAdded',
        } ),
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
            purchaseOrderList: ( state ) => state.PurchaseOrder.list,
        } ),

        /** @returns { Boolean } */
        actionIsBlocked() {
            return this.inFlight
                || !this.currentPurchaseOrderItemsAreValid
                || this.currentPurchaseOrderHasDuplicateItems;
        },

        /** @returns { Number } */
        indexOfReferencePurchaseOrder() {
            return this.purchaseOrderList
                .findIndex( ( purchaseOrder ) => purchaseOrder.number === this.currentPurchaseOrder.number );
        },
        /** @returns { Boolean } */
        canGoToNextPurchaseOrder() {
            return this.indexOfReferencePurchaseOrder < ( this.purchaseOrderList.length - 1 );
        },

        /** @returns { Boolean } */
        modalIsOpen() {
            return this.showChangesToReadOnlyStatusChangeModal || this.showPromptableLineItemsModal;
        },
    },
    methods: {
        ...mapMutations( {
            success: 'SET_SUCCESS_NOTIFICATION',
        } ),

        displayChangesToReadOnlyStatusModal() {
            this.showChangesToReadOnlyStatusChangeModal = true;
        },
        hideChangesToReadOnlyStatusModal() {
            this.showChangesToReadOnlyStatusChangeModal = false;
        },
        displayLineItemModificationsModal() {
            this.showPromptableLineItemsModal = true;
        },
        hideLineItemModificationsModal() {
            this.showPromptableLineItemsModal = false;
        },
        displayCannotAddLineItemsModal() {
            this.showLineItemsCannotBeAddedModal = true;
        },
        hideCannotAddLineItemsModal() {
            this.showLineItemsCannotBeAddedModal = false;
        },
        hideAllModals() {
            this.hideChangesToReadOnlyStatusModal();
            this.hideLineItemModificationsModal();
            this.hideCannotAddLineItemsModal();
        },
        setModalSaveButtonText( saveButtonText ) {
            this.modalSaveButtonText = saveButtonText;
        },

        /**
         * Return an object with the status of the currentPurchaseOrder
         * Used to send to the purchaseOrder/modify endpoint if the referencePurchaseOrder is
         * in a read-only status
         * @returns Object
         */
        getModifiedPurchaseOrderStatus() {
            return { status: this.currentPurchaseOrderModifications.status };
        },

        /**
         * Validate changes made to the currentPurchaseOrder,
         * launch a modal if needed,
         * otherwise, save with the provided saveFunction
         * @param { Function } saveFunction - savePurchaseOrder OR saveAndGoToNextPurchaseOrder
         * @param { String } modalSaveButtonText - one of the modalSaveButtonTypes
         */
        validateThenSave( saveFunction, modalSaveButtonText ) {
            if ( this.currentPurchaseOrderHasModifications ) {
                if ( this.mustPromptToAllowReadOnlyStatusChanges ) {
                    this.setModalSaveButtonText( modalSaveButtonText );
                    this.displayChangesToReadOnlyStatusModal();
                } else if ( this.mustPromptToAllowLineItemChangesToBeSaved ) {
                    this.setModalSaveButtonText( modalSaveButtonText );
                    this.displayLineItemModificationsModal();
                } else if ( this.mustPromptToShowLineItemsCannotBeModified ) {
                    this.displayCannotAddLineItemsModal();
                } else {
                    saveFunction( this.currentPurchaseOrderModifications );
                }
            } else {
                this.success( 'No changes to save.' );
                // Hack to go to the next PO if the user hits save & next and hasn't made any changes to the PO
                if ( modalSaveButtonText === this.modalSaveButtonTypes.saveAndNext ) {
                    setTimeout( () => this.showNextPurchaseOrder(), 1000 );
                }
            }
        },

        /**
         * Save purchase order changes
         * @param { Object } purchaseOrder
         */
        async savePurchaseOrder( purchaseOrder ) {
            this.inFlight = true;
            // Hide all modals if any are displayed
            if ( this.modalIsOpen ) this.hideAllModals();
            try {
                // If we have changes, add the purchaseOrder number, and ship it off to modify
                await this.$store.dispatch( 'PurchaseOrder/modify', {
                    purchaseOrder: {
                        ...purchaseOrder,
                        number: this.currentPurchaseOrder.number,
                    },
                } );
            } catch ( error ) {
                console.error( 'Error in savePurchaseOrder: ', error );
            } finally {
                this.inFlight = false;
            }
        },

        /**
         * Save purchase order change and then show the next purchase order
         * @param { Object } purchaseOrderModifications
         */
        async saveAndGoToNextPurchaseOrder( purchaseOrderModifications ) {
            try {
                await this.savePurchaseOrder( purchaseOrderModifications );
                this.showNextPurchaseOrder();
            } catch ( error ) {
                console.log( 'Error in saveAndGoToNextPurchaseOrder: ', error );
            }
        },

        /**
         * Go to the next purchase order if available, otherwise return to the summary view
         */
        showNextPurchaseOrder() {
            if ( this.canGoToNextPurchaseOrder ) {
                const nextPurchaseOrder = this.purchaseOrderList[ this.indexOfReferencePurchaseOrder + 1 ];
                this.$router.push( {
                    name: 'PurchaseOrderSearch',
                    query: { docNum: nextPurchaseOrder.number },
                } );
            } else {
                this.success( 'Completed Purchase Order List modifications' );
                this.$emit( 'showSummary' );
            }
        },
    },
};
</script>

<style scoped lang="scss">
    .po-status {
        display: flex;
        #dropdown-po-status {
            margin-left: 1rem;
            width: 150px;
        }

        &__label {
            margin-top: .3rem;
        }

    }
    .button-group {
        * {
            margin-right: 1rem;
        }
    }
</style>
