<template>
    <TableBaseFilterable
        :headers="relatedInvoicesHeaders"
        :items="currentPurchaseOrder.invoices"
        :filter.sync="relatedInvoicesFilter"
        :noDataText="relatedInvoicesResultsText"
        :hideAdd="cannotAddInvoices"
        @activator:click="addInvoice"
        @click:row="( e, { item } ) => openInvoiceDetailPage( item )"
    >
        <template #[`item.poNumbers`]="{ item }">
            <TextareaReadOnly :content="item.poNumbers.join( '\r\n' )" />
        </template>
        <template #[`item.invoiceDate`]="{ item }">
            {{ FormatUtil.dateTimeToSimpleString( item.invoiceDate ) }}
        </template>
        <template #[`item.total`]="{ item }">
            {{ FormatUtil.toCurrency( item.total ) }}
        </template>
    </TableBaseFilterable>
</template>

<script>
// Vuex
import { mapState, mapActions } from 'vuex';

// Routes
import InvoiceRoute from '@/router/routes/InvoiceReconciliation';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import TextareaReadOnly from '@/components/textareas/TextareaReadOnly';

// Constants
const validPOStatuses = [
    'SUBMITTED',
    'RECEIVED',
    'RECONCILE',
];

export default {
    name: 'PurchaseOrderLineList',
    components: {
        TextareaReadOnly,
        TableBaseFilterable,
    },
    onMount() {
        // Make a copy of the original ( database ) version of the purchaseOrderItemDTOList
        this.originalPurchaseOrderItemDTOList = [ ...this.currentPurchaseOrder?.purchaseOrderItemDTOList ];
    },
    data() {
        return {
            originalPurchaseOrderItemDTOList: [],
            FormatUtil,
            relatedInvoicesHeaders: [
                { text: 'Invoice Number', value: 'supplierDocumentNumber' },
                { text: 'Supplier', value: 'supplier' },
                { text: 'PO Numbers', value: 'poNumbers' },
                { text: 'Invoice Date', value: 'invoiceDate' },
                { text: 'Status', value: 'invoiceStatus' },
                { text: 'Total', value: 'total', align: 'end' },
            ],
            relatedInvoicesFilter: '',
        };
    },
    computed: {
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
        } ),
        /**
         * @returns { String }
         */
        relatedInvoicesResultsText() {
            return this.cannotAddInvoices
                ? 'Only available for active purchase orders.'
                : 'No related invoices found.';
        },
        /**
         * Check if we are allowed to show the add button or not. A
         * purchase order cannot add related invoices unless it has line items.
         * @returns { Boolean }
         */
        cannotAddInvoices() {
            return !validPOStatuses.includes( this.currentPurchaseOrder.status )
                || !this.originalPurchaseOrderItemDTOList > 0;
        },
    },
    methods: {
        ...mapActions( {
            setFilterPurchaseOrderNumber: 'Invoice/setFilterPurchaseOrderNumber',
        } ),
        async addInvoice() {
            await this.setFilterPurchaseOrderNumber( {
                purchaseOrderNumber: this.currentPurchaseOrder.number,
            } );
            this.$router.push( {
                path: `${ InvoiceRoute.path }/create`,
                query: {
                    locationId: this.currentPurchaseOrder.location.id,
                    supplierId: this.currentPurchaseOrder.supplier.id,
                },
            } );
        },
        openInvoiceDetailPage( { id } ) {
            this.$router.push( `${ InvoiceRoute.path }/${ id }` );
        },
    },
};
</script>
