<template>
    <section>
        <ModalMovePurchaseOrderLines
            v-model="showModal"
            :selectedItems="selectedItems"
            @success="$emit( 'success' )"
        />

        <v-btn
            color="primary"
            :disabled="disabled"
            @click.prevent="showModal = true"
        >
            <v-icon>mdi-arrow-all</v-icon>
            Move
        </v-btn>
    </section>
</template>

<script>
// Components
import ModalMovePurchaseOrderLines from './buttonMovePurchaseOrderLines/ModalMovePurchaseOrderLines';

/**
 * @emits 'success'
 */
export default {
    name: 'ButtonMovePurchaseOrderLines',
    components: {
        ModalMovePurchaseOrderLines,
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectedItems: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showModal: false,
        };
    },
};
</script>
