<template>
    <v-dialog
        v-model="showModal"
        class="modal-cannot-add-line-items"
        maxWidth="600"
        persistent
    >
        <v-card>
            <v-card-title class="primary white--text">
                <h3>Cannot Save New Purchase Order Items</h3>
                <v-spacer />
                <v-btn
                    icon
                    @click="closeModal()"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <p>In order add lines to a Purchase Order in the
                    <strong>Received</strong>,
                    <strong>Reconciled</strong>, or
                    <strong>Submitted</strong> status,
                    you must <strong>first</strong> change the status and save, <strong>THEN</strong> add lines.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    outlined
                    @click="closeModal()"
                >
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalCannotAddLineItems',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        showModal: {
            get() {
                return this.value;
            },
            set( value ) {
                this.$emit( 'input', value );
            },
        },
    },
    methods: {
        closeModal() {
            this.showModal = false;
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog > .v-card {
    .v-card__actions {
        padding-bottom: 1rem;
    }
}
</style>
