<template>
    <section>
        <ModalRemovePurchaseOrderLineItems
            v-model="showModal"
            :selectedItems="selectedItems"
            @confirm="$emit( 'confirm' )"
        />

        <v-btn
            :disabled="disabled"
            outlined
            @click.prevent="showModal = true"
        >
            <v-icon>mdi-delete</v-icon>
            Remove
        </v-btn>
    </section>
</template>

<script>
// Components
import ModalRemovePurchaseOrderLineItems from './buttonRemovePurchaseOrderItems/ModalRemovePurchaseOrderLineItems';

/**
 * @emits 'confirm'
 */
export default {
    name: 'ButtonRemovePurchaseOrderItem',
    components: {
        ModalRemovePurchaseOrderLineItems,
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectedItems: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showModal: false,
        };
    },
};
</script>
