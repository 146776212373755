<template>
    <v-dialog
        v-model="showModal"
        class="modal-remove-purchase-order-line-items"
        maxWidth="1300"
        persistent
    >
        <v-card>
            <v-card-title class="primary white--text">
                <h3>Remove Purchase Order Items</h3>
                <v-spacer />
                <v-btn
                    icon
                    @click="closeModal()"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <p>Are you sure you want to remove all of the following line items from this Purchase Order?</p>
                <p>These changes will only be saved <strong>AFTER</strong> you hit the 'Save' button.</p>
                <TableBaseFilterable
                    :filter.sync="table.filter"
                    :headers="table.headers"
                    :items="selectedItems"
                    :itemsPerPage="10"
                    filterLabel="Filter Purchase Order Lines..."
                    sortBy="titleDescription"
                    hideAdd
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    outlined
                    @click="closeModal()"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="confirmButtonHandler()"
                >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

/**
 * @emits 'confirm'
 */
export default {
    name: 'ModalRemovePurchaseOrderLineItems',
    components: {
        TableBaseFilterable,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        selectedItems: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            table: {
                filter: '',
                headers: [
                    {
                        text: 'Condition',
                        value: 'condition',
                        width: 50,
                    },
                    {
                        text: 'EAN',
                        value: 'productCode',
                    },
                    {
                        text: 'Title',
                        value: 'titleDescription',
                        width: 300,
                    },
                    {
                        text: 'Quantity',
                        value: 'requestedQuantity',
                        align: 'end',
                        width: 100,
                    },
                    {
                        text: 'Price',
                        value: 'requestedPrice',
                        align: 'end',
                        width: 100,
                    },
                    {
                        text: 'Expected Receive Date',
                        value: 'expectedReceiveDate',
                        width: 80,
                    },
                    {
                        text: 'Notes',
                        value: 'note',
                        width: 300,
                    },
                ],
            },
        };
    },
    computed: {
        showModal: {
            get() {
                return this.value;
            },
            set( value ) {
                this.$emit( 'input', value );
            },
        },
    },
    methods: {
        closeModal() {
            this.showModal = false;
        },
        confirmButtonHandler() {
            this.$emit( 'confirm' );
            this.closeModal();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog > .v-card {
    .v-card__actions {
        padding-bottom: 1rem;
    }
}
</style>
