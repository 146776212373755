<template>
    <v-form class="sidebar-container" @submit.prevent="postFiltersToGetPurchaseOrders()">
        <!-- PO Number Quick Search -->
        <div class="sidebar-container__section">
            <h3 class="sidebar-container__header">Purchase Order Number</h3>
            <v-text-field
                v-model="purchaseOrderNumber"
                type="text"
                dense
                hideDetails
                backgroundColor="white"
                name="expected-receive-date-start-date"
                placeholder="Purchase Order Number"
                outlined
                @keydown.enter="postPurchaseOrderNumberToGetPurchaseOrders()"
            />
        </div>

        <!-- Filters -->
        <div
            id="filter"
            class="sidebar-container__section"
        >
            <!-- Expected Receive Date -->
            <div class="search-parameter sidebar-container__section">
                <h3 class="sidebar-container__header">Expected Receive Date</h3>
                <div class='search-parameter__row'>
                    <InputDateRange
                        :start-date.sync="expectedReceiveStartDate"
                        :end-date.sync="expectedReceiveEndDate"
                        label="Expected Receive Date"
                        outlined
                    />
                </div>
            </div>

            <!-- Create Date -->
            <div class="search-parameter sidebar-container__section">
                <h3 class="sidebar-container__header">Create Date</h3>
                <div class='search-parameter__row'>
                    <InputDateRange
                        :start-date.sync="createStartDate"
                        :end-date.sync="createEndDate"
                        label="Create Date"
                        outlined
                    />
                </div>
            </div>

            <!-- Transmit Date -->
            <div class="search-parameter sidebar-container__section">
                <h3 class="sidebar-container__header">Transmit Date</h3>
                <div class='search-parameter__row'>
                    <InputDateRange
                        :start-date.sync="transmitStartDate"
                        :end-date.sync="transmitEndDate"
                        label="Transmit Date"
                        outlined
                    />
                </div>
            </div>

            <div class='logistics-section sidebar-container__section'>
                <!-- EAN -->
                <div
                    id="ean-filter"
                    class="two-columns"
                >
                    <h3 class="sidebar-container__header">EAN</h3>
                    <v-text-field
                        v-model="productCode"
                        type="text"
                        dense
                        hideDetails
                        backgroundColor="white"
                        placeholder="Product Code"
                        class="logistics-section__input"
                        outlined
                    />
                </div>

                <!-- Location Selection -->
                <div class="two-columns">
                    <h3 class="sidebar-container__header">Location</h3>
                    <span class="logistics-section__input">
                        <DropdownLocationList
                            v-model="location"
                            outlined
                        />
                    </span>
                </div>

                <!-- Primary Supplier Selection -->
                <div
                    id="supplier"
                    class="two-columns search-parameter"
                >
                    <h3 class="sidebar-container__header">Supplier</h3>
                    <span class="logistics-section__input">
                        <DropdownPrimarySupplier
                            v-model="supplier"
                            outlined
                            :hideDetails="true"
                        />
                    </span>
                </div>
            </div>

            <!-- Status -->
            <div
                id="status"
                class="two-columns search-parameter sidebar-container__section"
            >
                <h3 class="sidebar-container__header">Status</h3>
                <CheckboxMultiSelectPersistable
                    v-model="statusList"
                    :leftColumnKeys="leftColumnKeys"
                />
            </div>

            <!-- NOTES -->
            <div
                id="notes"
                class="two-columns search-parameter sidebar-container__section"
            >
                <h3 class="sidebar-container__header">Note</h3>
                <span>
                    <v-text-field
                        v-model="notesStartWith"
                        type="text"
                        dense
                        hideDetails
                        placeholder="Starts with..."
                        backgroundColor="white"
                        outlined
                    />
                </span>
            </div>

        </div>

        <!-- Action Buttons -->
        <div
            id="button-container"
            class="sidebar-container__section"
        >
            <v-btn
                outlined
                color="white"
                @click="clearFilters()"
            >
                Clear
            </v-btn>

            <v-btn
                class="button-search"
                type="submit"
            >
                Search
            </v-btn>
        </div>
    </v-form>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Constants
import { STATUS_LIST_COLUMNS } from '@/constants/purchaseOrders/statusList';

// Components
import DropdownLocationList from '@/views/common/dropdowns/DropdownLocationList';
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';
import CheckboxMultiSelectPersistable from '@/components/base/multiselect/CheckboxMultiSelectPersistable';
import InputDateRange from '@/components/InputDateRange';

export default {
    name: 'SidebarFilterPurchaseOrders',
    components: {
        InputDateRange,
        CheckboxMultiSelectPersistable,
        DropdownLocationList,
        DropdownPrimarySupplier,
    },
    computed: {
        ...mapGetters( {
            hasPrimarySuppliers: 'GlobalResources/hasPrimarySupplierList',
        } ),
        ...mapState( {
            purchaseOrderStatusList: ( state ) => state.PurchaseOrder.purchaseOrderStatusList,
            filters: ( state ) => state.PurchaseOrder.filters,
        } ),
        leftColumnKeys() {
            return this.purchaseOrderStatusList.filter(
                ( status ) => STATUS_LIST_COLUMNS[ status.key ] === 0,
            ).map( ( status ) => status.key );
        },

        purchaseOrderNumber: {
            get() {
                return this.filters.purchaseOrderNumber;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterPurchaseOrderNumber', { purchaseOrderNumber: val } );
            },
        },
        expectedReceiveStartDate: {
            get() {
                return this.filters.expectedReceiveStartDate;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterExpectedReceiveStartDate', { expectedReceiveStartDate: val } );
            },
        },
        expectedReceiveEndDate: {
            get() {
                return this.filters.expectedReceiveEndDate;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterExpectedReceiveEndDate', { expectedReceiveEndDate: val } );
            },
        },
        createStartDate: {
            get() {
                return this.filters.createStartDate;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterCreateStartDate', { createStartDate: val } );
            },
        },
        createEndDate: {
            get() {
                return this.filters.createEndDate;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterCreateEndDate', { createEndDate: val } );
            },
        },
        transmitStartDate: {
            get() {
                return this.filters.transmitStartDate;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterTransmitStartDate', { transmitStartDate: val } );
            },
        },
        transmitEndDate: {
            get() {
                return this.filters.transmitEndDate;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterTransmitEndDate', { transmitEndDate: val } );
            },
        },
        location: {
            get() {
                return this.filters.location;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterLocation', { location: val } );
            },
        },
        productCode: {
            get() {
                return this.filters.productCode;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterProductCode', { productCode: val } );
            },
        },
        supplier: {
            get() {
                return this.filters.supplier;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterSupplier', { supplier: val } );
            },
        },
        statusList: {
            get() {
                return this.filters.statusList;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterStatusList', { statusList: val } );
            },
        },
        notesStartWith: {
            get() {
                return this.filters.notesStartWith;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setFilterNotesStartWith', { notesStartWith: val } );
            },
        },

    },
    beforeMount() {
        this.$store.dispatch( 'GlobalResources/init' );
    },

    methods: {
        clearFilters() {
            this.$store.dispatch( 'PurchaseOrder/clearFilters' );
        },
        showSummaryView() {
            this.$emit( 'showSummaryView' );
        },
        postPurchaseOrderNumberToGetPurchaseOrders() {
            this.$store.dispatch( 'PurchaseOrder/postPurchaseOrderNumberToGetPurchaseOrders' );
            this.showSummaryView();
        },
        postFiltersToGetPurchaseOrders() {
            this.$store.dispatch( 'PurchaseOrder/postFiltersToGetPurchaseOrders' );
            this.showSummaryView();
        },
    },
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables.scss";
    @import "@/assets/sass/sidebar.scss";
    @import "@/assets/sass/functions.scss";

    .sidebar-container {
        ::v-deep .v-input .v-input__control {
            border-radius: 4px;
        }
    }

    .two-columns {
        display:grid;
        align-items: center;
        grid-template-columns: 25% auto;
    }

    .logistics-section {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &__input {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            width: 270px;
        }
    }

    #status h3 {
        align-self: start;
    }

    ::v-deep.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
        padding-left: 0.5rem;
    }

    #filter {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    #button-container {
        display: flex;
        justify-content: space-around;
        border-bottom: none;

        .button-search {
            color: var(--v-primary-base);
        }
    }

    .search-parameter {
        &__row {
            display: flex;
            align-items: center;
            .v-input {
                margin-top: 0;
                width: rem-calc(165);
            }
            p {
                margin: 0 0.5rem 0;
            }
        }
    }
</style>
