<template>
    <div
        class="custom-container"
        :class="[ fullSidebar ? 'sidebar-full' : 'sidebar-thin' ]"
    >
        <ClConfirmationModal
            v-model="showConfirmationModal"
            titleText="Leave without saving?"
            :roundButton="false"
            @confirm="confirmRouteLeave()"
            @cancel="cancelRouteLeave()"
        >
            <template #content>
                <p>You have unsaved changes. Are you sure you want to leave without saving?</p>
            </template>
        </ClConfirmationModal>

        <TheSidebar
            :fullSize="fullSidebar"
        >
            <SidebarFilterPurchaseOrders
                v-show="fullSidebar"
                @showSummaryView="$router.push( { name: 'PurchaseOrderSearch' } )"
            />
        </TheSidebar>

        <ThePageHeadingBar
            :pageHeading="pageHeading"
            :fullSize="fullSidebar"
            :backArrow="showDetailView"
            @goBack="$router.push( { name: 'PurchaseOrderSearch' } )"
        />

        <section class="main">
            <!-- PO Detail View -->
            <DetailView
                v-show="showDetailView"
                @showSummary="$router.push( { name: 'PurchaseOrderSearch' } )"
            />
            <!-- PO Summary View -->
            <SummaryView
                v-show="!showDetailView"
                @showPoDetail="( purchaseOrder ) => $router.push( { name: 'PurchaseOrderSearch', query: { docNum: purchaseOrder.number } } )"
            />
        </section>

    </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TheSidebar from '@/views/common/TheSidebar';

import SidebarFilterPurchaseOrders from './search/SidebarFilterPurchaseOrders';
import SummaryView from './search/SummaryView';
import DetailView from './search/DetailView';

export default {
    name: 'PurchaseOrderSearch',
    components: {
        ThePageHeadingBar,
        TheSidebar,
        DetailView,
        SummaryView,
        SidebarFilterPurchaseOrders,
    },
    // Handles navigation away from route
    beforeRouteLeave( to, from, next ) {
        this.handleReroute( to, from, next );
    },
    // Handles navigation from detail page to search page
    beforeRouteUpdate( to, from, next ) {
        this.handleReroute( to, from, next );
    },
    props: {
        docNum: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            pageHeading: '',
            fullSidebar: true,
            showDetailView: false,
            showConfirmationModal: false,
            to: null,
        };
    },
    computed: {
        ...mapGetters( {
            hasPurchaseOrderList: 'PurchaseOrder/hasPurchaseOrders',
            purchaseOrderCount: 'PurchaseOrder/purchaseOrderCount',
            currentPurchaseOrderHasModifications: 'PurchaseOrder/Detail/hasModifications',
        } ),
        ...mapState( {
            purchaseOrderList: ( state ) => state.PurchaseOrder.list,
        } ),
        /** @returns { Boolean } */
        noPurchaseOrderSearchResults() {
            return this.purchaseOrderCount === 0;
        },
        /** @returns { Array } */
        docNumMatchesFromList() {
            return this.purchaseOrderList.filter( ( purchaseOrder ) => purchaseOrder.number === this.docNum );
        },
        /** @returns { Boolean } */
        docNumNotFoundInList() {
            return this.docNumMatchesFromList.length === 0;
        },
        canLeaveRoute() {
            return !this.showDetailView
                || !this.currentPurchaseOrderHasModifications
                || this.to;
        },
    },
    watch: {
        docNum: {
            handler: 'initialize',
            immediate: true,
        },
    },
    beforeDestroy() {
        this.$store.commit( 'PurchaseOrder/Detail/CLEAR_CURRENT_PURCHASE_ORDER' );
    },
    methods: {
        ...mapMutations( {
            error: 'SET_ERROR_NOTIFICATION',
            clearNotifications: 'CLEAR_ALL_NOTIFICATIONS',
        } ),
        async initialize() {
            if ( ( this.noPurchaseOrderSearchResults || this.docNumNotFoundInList ) && this.docNum ) {
                await this.$store.dispatch( 'PurchaseOrder/getByFilters', {
                    purchaseOrderNumber: this.docNum,
                } );
                await this.renderPage();
            } else {
                await this.renderPage();
            }
        },
        async renderPage() {
            if ( this.docNum ) {
                // detail
                const purchaseOrders = this.docNumMatchesFromList;

                if ( purchaseOrders.length === 1 ) {
                    await this.loadDetailView( purchaseOrders[ 0 ] );
                } else {
                    this.loadSummaryView();
                    if ( purchaseOrders.length === 0 ) {
                        this.error( `No purchase order with PO number ${ this.docNum } was found.` );
                    } else if ( purchaseOrders.length > 1 ) {
                        this.error( `Multiple catalog listings with PO number ${ this.docNum } were found.` );
                    }
                }
            } else {
                this.loadSummaryView();
            }
        },
        async loadDetailView( purchaseOrder ) {
            await this.setAndPrepareCurrentPurchaseOrder( purchaseOrder );
            this.showDetailView = true;
            this.fullSidebar = false;
            this.pageHeading = `Purchase Order Detail -
                ${ FormatUtil.locationNameFriendly( purchaseOrder.location.name ) },
                ${ FormatUtil.dateTimeToSimpleString( purchaseOrder.createDate ) }`;
        },
        loadSummaryView() {
            this.showDetailView = false;
            this.fullSidebar = true;
            this.pageHeading = 'Purchase Order Search';
            // why are we clearing the success message here? it should clear at every route boundary, which now is
            // also between summary and detail view
            this.clearNotifications();
        },
        async setAndPrepareCurrentPurchaseOrder( purchaseOrder ) {
            // Create an id attribute on the purchaseOrder from the number so the GenericList has a point of reference
            await this.$store.dispatch( 'PurchaseOrder/Detail/setCurrentPurchaseOrder', {
                purchaseOrder: {
                    ...purchaseOrder,
                    id: purchaseOrder.number,
                },
            } );
            this.$store.commit( 'PurchaseOrder/Detail/PREPARE_CURRENT_PURCHASE_ORDER_LINE_ITEMS' );
        },
        confirmRouteLeave() {
            this.$router.push( this.to );
            this.to = null;
        },
        cancelRouteLeave() {
            this.to = null;
        },
        handleReroute( to, from, next ) {
            if ( this.canLeaveRoute ) {
                next();
            } else {
                this.to = to;
                this.showConfirmationModal = true;
            }
        },
    },
};
</script>

<style scoped>
    main{
        grid-area: main;
        padding-bottom: 75px;
    }
</style>
