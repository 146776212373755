<template>
    <section>
        <!-- Error Message Modal -->
        <v-dialog
            v-model="showModal"
            maxWidth="800"
            persistent
        >
            <v-card>
                <v-card-title class="error white--text">
                    <v-icon
                        color="white"
                        class="error-modal__header__icon"
                    >
                        mdi-alert-circle-outline
                    </v-icon>
                    Import Error
                </v-card-title>
                <v-card-text>
                    <div
                        v-for="( error, index ) in importErrors"
                        :key="index"
                    >
                        <div class="error-entry">
                            <div class="error-entry__line">
                                <v-icon xSmall color="error">mdi-circle</v-icon>
                                <span class="error-entry__line__message">Error: {{ error.message }}</span>
                            </div>
                            <div v-if="error.row" class="error-entry__line">
                                <span class="error-entry__line__row">Row: {{ error.row }}</span>
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        outlined
                        @click="hideModal()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Import Button -->
        <v-btn
            outlined
            :disabled="disabled"
            @click="clickHandler()"
        >
            <v-icon>mdi-arrow-down</v-icon>
            Import
        </v-btn>
        <input
            id="purchase-order-import"
            ref="input-import-purchase-order-lines"
            type="file"
            @change="readFile"
        >
    </section>
</template>

<script>
export default {
    name: 'ImportPurchaseOrderLines',
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showModal: false,
            importErrors: [],
            expectedCSVColumns: [
                'condition',
                'productCode',
                'titleDescription',
                'requestedQuantity',
                'requestedPrice',
                'expectedReceiveDate',
                'backordered',
                'note',
            ],
        };
    },
    methods: {
        displayModal() {
            this.showModal = true;
        },
        hideModal() {
            this.showModal = false;
            this.importErrors = [];
        },
        /**
         * Trim and remove surrounding double quotes
         * https://stackoverflow.com/questions/19156148/i-want-to-remove-double-quotes-from-a-string
         * */
        format( value ) {
            return value
                .toString()
                .trim()
                .replace( /^"(.+(?="$))"$/, '$1' );
        },
        addImportError( message, row ) {
            this.importErrors.push( { message, row } );
        },

        // Totally lifted from:
        // https://stackoverflow.com/a/41563966
        csvToArray( text ) {
            let p = '';
            let row = [ '' ];
            const ret = [ row ];
            let i = 0;
            let r = 0;
            let s = !0;
            let l;

            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for ( l in text ) {
                l = text[ l ];
                if ( l === '"' ) {
                    if ( s && l === p ) row[ i ] += l;
                    s = !s;
                    // eslint-disable-next-line no-multi-assign,no-plusplus
                } else if ( l === ',' && s ) l = row[ ++i ] = '';
                else if ( l === '\n' && s ) {
                    if ( p === '\r' ) row[ i ] = row[ i ].slice( 0, -1 );
                    // eslint-disable-next-line no-multi-assign,no-plusplus
                    row = ret[ ++r ] = [ l = '' ]; i = 0;
                } else row[ i ] += l;
                p = l;
            }
            return ret;
        },

        readFile( event ) {
            // Idea from: https://alligator.io/vuejs/file-reader-component/
            const file = event.target.files[ 0 ];
            const reader = new FileReader();

            // Callback to fire once we readAsText
            reader.onload = ( e ) => this.validateFile( e.target.result );
            // Read the file to call the previously defined callback
            reader.readAsText( file );
        },
        clickHandler() {
            this.$refs[ 'input-import-purchase-order-lines' ].click();
        },
        validateFile( fileString ) {
            // Split the into arrays at the line ending
            let fileRows = this.csvToArray( fileString );
            if ( !fileRows ) {
                this.addImportError( 'Failed to import file.' );
            }
            // Remove the header row
            fileRows.splice( 0, 1 );

            // Remove empty rows
            fileRows = fileRows.filter( ( row ) => row.length > 1 );

            if ( fileRows.length === 0 ) {
                this.addImportError( 'There were no importable lines in the file.' );
            }

            fileRows.forEach( ( rowArray ) => {
                if ( rowArray.length !== this.expectedCSVColumns.length ) {
                    // Check if each row has the required 7 columns
                    this.addImportError( `Row must have ${ this.expectedCSVColumns.length } columns. Was ${ rowArray.length }.`, rowArray );
                } else if ( rowArray[ 1 ].toString().length !== 13 ) {
                    // Check if the productCode is of length 13
                    this.addImportError( `Product Code must be 13 digits. Was ${ rowArray[ 1 ].toString().length }.`, rowArray );
                } else {
                    this.importLineIntoCurrentPurchaseOrder( rowArray );
                }
            } );

            // If we have errors in the import process, show the modal
            if ( this.importErrors.length > 0 ) {
                this.displayModal();
            }
        },
        importLineIntoCurrentPurchaseOrder( lineItem ) {
            this.$store.commit( 'PurchaseOrder/Detail/ADD_CURRENT_PURCHASE_ORDER_LINE_ITEM', {
                condition: this.format( lineItem[ 0 ] ),
                productCode: this.format( lineItem[ 1 ] ),
                titleDescription: this.format( lineItem[ 2 ] ),
                requestedQuantity: this.format( parseInt( lineItem[ 3 ], 10 ) ),
                requestedPrice: this.format( parseFloat( lineItem[ 4 ] ) ),
                expectedReceiveDate: this.format( lineItem[ 5 ] ),
                backordered: this.format( lineItem[ 6 ] ).toLowerCase() === 'true',
                note: this.format( lineItem[ 7 ] ),
            } );
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/purchaseorder.scss";

/* Don't display the usual file upload area that shows the selected file name */
#purchase-order-import {
    display: none;
}
.error-modal__header__icon {
    margin-right: .5rem;
    font-size: 32px;
}
.error-entry {
    display: flex;
    flex-direction: column;
    margin: .75rem 0;
    padding-bottom: .75rem;
    border-bottom: solid 1px $light-gray;
    &__line {
        display: flex;
        align-items: center;
        &__message {
            margin-left: 0.25rem;
            font-weight: bold;
        }
        &__row {
            margin-left: 1rem;
            font-size: 0.75rem;
            color: $dark-gray;
        }
    }
}
::v-deep .v-dialog > .v-card {
    .v-card__actions {
        padding-bottom: 1rem;
    }
}
</style>
