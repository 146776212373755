<template>
    <v-dialog
        v-model="showModal"
        class="modal-notify-only-status-can-change"
        maxWidth="800"
    >
        <v-card>
            <v-card-title class="primary white--text">
                <h3>Read-Only Status Change</h3>
                <v-spacer />
                <v-btn
                    icon
                    @click="closeModal()"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <p>This purchase order was previously in the status: <strong>{{ previousStatus }} </strong></p>
                <p>Because this is a <strong>read-only</strong> status, you can only modify the status on this purchase order.
                    Any additional changes must be made <span class="strong"> AFTER </span> the status change has been saved.</p>
                <br/>
                <div v-if="statusHasChanged">
                    <p>Do you want to continue and save <strong>ONLY</strong> the change made to the purchase order's status?</p>
                    <strong>Any additional changes will be discarded.</strong>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <!-- Dismiss -->
                <v-btn
                    outlined
                    @click="closeModal()"
                >
                    Dismiss
                </v-btn>
                <!-- Save -->
                <v-btn
                    v-if="statusHasChanged"
                    color="primary"
                    @click="save()"
                >
                    {{ saveButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalNotifyOnlyStatusCanChange',
    props: {
        saveButtonText: {
            type: String,
            required: true,
        },
        previousStatus: {
            type: String,
            required: true,
        },
        statusHasChanged: {
            type: Boolean,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        showModal: {
            get() {
                return this.value;
            },
            set( value ) {
                this.$emit( 'input', value );
            },
        },
    },
    methods: {
        closeModal() {
            this.showModal = false;
        },
        save() {
            this.$emit( 'save' );
        },
        saveAndNext() {
            this.$emit( 'saveAndNext' );
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog > .v-card {
    .v-sheet {
        margin-bottom: 1rem;
    }
    .v-card__actions {
        padding-bottom: 1rem;
    }
}
</style>
