<template>
    <v-textarea
        :value="content"
        :rows="rows"
        outlined
        hideDetails
        readonly
    />
</template>

<script>
export default {
    name: 'TextareaReadOnly',
    props: {
        content: {
            type: String,
            required: true,
        },
        rows: {
            type: Number,
            required: false,
            default: 5,
        },
    },
};
</script>
