<template>
    <v-dialog
        v-model="showModal"
        class="modal-move-purchase-order-lines"
        maxWidth="800"
        persistent
    >
        <v-card :loading="moveItemsInFlight">
            <v-card-title class="primary white--text">
                <h3>Move Purchase Order Items</h3>
                <v-spacer />
                <v-btn
                    icon
                    @click="closeModal()"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <div>
                        <label for="button-new-purchase-order">
                            Move Items to
                        </label>
                        <br/>
                        <v-btn
                            id="button-new-purchase-order"
                            :disabled="inFlight"
                            outlined
                            @click="moveItemsToNewPurchaseOrder()"
                        >
                            New Purchase Order
                        </v-btn>
                    </div>
                    <v-spacer/>
                    <div>
                        <label for="dropdown-primary-supplier">
                            Or Existing
                        </label>
                        <DropdownPrimarySupplier
                            id="dropdown-primary-supplier"
                            v-model="primarySupplier"
                            :disabled="inFlight"
                            hideDetails
                        />
                    </div>
                </v-row>
                <TableBaseFilterable
                    v-if="hasSelectedPrimarySupplier"
                    class="table-purchase-order-select"
                    :filter.sync="table.filter"
                    :headers="table.headers"
                    :items="purchaseOrders.list"
                    :itemsPerPage="10"
                    :loading="inFlight"
                    :sortDesc="false"
                    filterLabel="Filter Purchase Orders..."
                    sortBy="locationName"
                    hideAdd
                    @click:row="( e, { item } ) => moveItemsToExistingPurchaseOrder( item )"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Api
import PurchaseOrderApi from '@/api/PurchaseOrderApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';

/**
 * @emits 'success'
 */
export default {
    name: 'ModalMovePurchaseOrderLines',
    components: {
        TableBaseFilterable,
        DropdownPrimarySupplier,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        selectedItems: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            table: {
                filter: '',
                headers: [
                    {
                        text: 'Purchase Order Number',
                        value: 'number',
                    },
                    {
                        text: 'Location',
                        value: 'locationName',
                    },
                    {
                        text: 'Note',
                        value: 'description',
                    },
                ],
            },

            supplier: {},
            purchaseOrders: {
                list: [],
                inFlight: false,
            },
            moveItemsInFlight: false,
        };
    },
    computed: {
        ...mapGetters( {
            authToken: 'User/authString',
        } ),
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
        } ),

        /** @returns { Boolean } */
        inFlight() {
            return this.purchaseOrders.inFlight || this.moveItemsInFlight;
        },

        /** @returns { Boolean } */
        hasSelectedPrimarySupplier() {
            return !!this.supplier && Object.keys( this.supplier ).length > 0;
        },

        showModal: {
            get() {
                return this.value;
            },
            set( value ) {
                this.resetData();
                this.$emit( 'input', value );
            },
        },
        primarySupplier: {
            get() {
                return this.supplier;
            },
            set( value ) {
                if ( value ) {
                    this.supplier = value;
                } else {
                    // Ensure supplier never gets set to null,
                    // that would upset DropdownPrimarySupplier
                    this.supplier = {};
                    // Reset purchaseOrders.list
                    this.purchaseOrders.list = [];
                }
            },
        },
    },
    watch: {
        supplier() {
            if ( this.hasSelectedPrimarySupplier ) {
                this.getPurchaseOrdersForSupplier( this.supplier );
            }
        },
    },
    methods: {
        resetData() {
            this.supplier = {};
            this.purchaseOrders.list = [];
            this.purchaseOrders.inFlight = false;
            this.moveItemsInFlight = false;
        },
        closeModal() {
            this.showModal = false;
        },

        async getPurchaseOrdersForSupplier( { id } ) {
            this.purchaseOrders.inFlight = true;
            try {
                const { data } = await PurchaseOrderApi.postFiltersToGetPurchaseOrderList( {
                    authToken: this.authToken,
                    supplierId: id,
                    sourcingDocStatusList: [ 'DRAFT' ],
                } );
                this.purchaseOrders.list = data.purchaseOrderDetailDTOList
                    .map( ( purchaseOrder ) => ( {
                        id: `${ purchaseOrder.number }${ purchaseOrder.location.id }`,
                        number: purchaseOrder.number,
                        locationName: FormatUtil.locationNameToPrettyName( purchaseOrder.location.name ),
                        description: purchaseOrder.description,
                    } ) );
            } catch ( error ) {
                this.purchaseOrders.list = [];
                console.error( 'Error in getPurchaseOrdersForSupplier: ', error );
            } finally {
                this.purchaseOrders.inFlight = false;
            }
        },

        closeModalWithDelay() {
            // If we don't set a timeout, the currentPurchaseOrder doesn't properly update
            setTimeout( () => this.closeModal(), 1000 );
        },

        async movePurchaseOrderItems( payload, errorMessage ) {
            try {
                await this.$store.dispatch( 'PurchaseOrder/moveLineItems', payload );
                this.$emit( 'success' );
                this.closeModalWithDelay();
            } catch ( error ) {
                this.$store.commit(
                    'SET_ERROR_NOTIFICATION',
                    errorMessage,
                    { root: true },
                );
            }
        },
        async moveItemsToExistingPurchaseOrder( { number } ) {
            await this.movePurchaseOrderItems( {
                fromPurchaseOrderNumber: this.currentPurchaseOrder.number,
                toPurchaseOrderNumber: number,
                itemIDList: this.selectedItems.map( ( item ) => item.id ),
            }, `Error occurred while attempting to move the selected Purchase Order Items to Purchase Order #${ number }` );
        },
        async moveItemsToNewPurchaseOrder() {
            await this.movePurchaseOrderItems( {
                fromPurchaseOrderNumber: this.currentPurchaseOrder.number,
                toPurchaseOrderNumber: null,
                itemIDList: this.selectedItems.map( ( item ) => item.id ),
            }, 'Error occurred while attempting to move the selected Purchase Order Items to a new Purchase Order' );
        },
    },
};
</script>

<style lang="scss">
.v-dialog {
    .v-card__text {
        margin-top: 2rem;
        .row {
            margin-right: 1rem;
            margin-bottom: 1rem;
            margin-left: 1rem;
        }
    }
    .table-purchase-order-select {
        margin-top: 1rem;
    }
}
</style>
