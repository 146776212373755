<template>
    <v-autocomplete
        v-model="purchaseOrderStatus"
        :items="purchaseOrderStatusList"
        itemText="key"
        dense
        hideDetails
        outlined
    />
</template>

<script>
// Vuex
import { mapState } from 'vuex';

export default {
    name: 'TheDropdownPurchaseOrderStatus',
    computed: {
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
            purchaseOrderStatusList: ( state ) => state.PurchaseOrder.purchaseOrderStatusList,
        } ),
        purchaseOrderStatus: {
            get() {
                return this.currentPurchaseOrder.status;
            },
            set( status ) {
                this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_STATUS', { status } );
            },
        },
    },
};
</script>
