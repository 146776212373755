<template>
    <ModalBase
        class="credit-card-modal stylized"
        :containerStyleOverride="containerStyle"
        :shouldShowCloseButton="false"
        @close="closeModal()"
    >
        <div slot="header" class="credit-card-modal__header">
            <h3>Credit Card Information</h3>
        </div>
        <!-- Override default body -->
        <div
            slot="body"
            class="credit-card-modal__body"
        >
            <div class="credit-card-modal__body__input">
                <h4 class="credit-card-modal__body__input__header">Notes</h4>
                <label for="note">
                </label>
                <v-textarea
                    id="note"
                    v-model="note"
                    outlined
                    dense
                    hideDetails
                ></v-textarea>
            </div>

            <div class="credit-card-modal__body__input">
                <h4 class="credit-card-modal__body__input__header">Last four numbers of credit card</h4>
                <label for="lastFour">
                    <v-text-field
                        id="lastFour"
                        v-model.number="lastFour"
                        maxlength="4"
                        outlined
                        dense
                        hideDetails
                    />
                </label>
            </div>
        </div>
        <div slot="footer" class="credit-card-modal__footer">
            <div class="credit-card-modal__footer__text">Changes will not be saved until "Save" is clicked on the main screen</div>
            <div class="credit-card-modal__footer__action">
                <v-btn
                    ref="closeModalButton"
                    color="primary"
                    @click="closeModal()"
                >
                    Close
                </v-btn>
            </div>
        </div>
    </ModalBase>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import FormUtil from '@/utils/FormUtil';

// Components
import ModalBase from '@/components/base/modals/ModalBase';

export default {
    name: 'ModalCreditCardInfo',
    components: {
        ModalBase,
    },
    data() {
        return {
            containerStyle: {
                width: '440px',
            },
        };
    },
    computed: {
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
        } ),
        lastFour: {
            get() {
                return this.currentPurchaseOrder.purchaseOrderCreditCardDTO.lastFour;
            },
            set( lastFour ) {
                if ( FormUtil.isWholeNumber( lastFour ) || ( lastFour === '' ) ) {
                    this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_CREDIT_CARD_LAST_FOUR', { lastFour } );
                }
            },
        },
        note: {
            get() {
                return this.currentPurchaseOrder.purchaseOrderCreditCardDTO.note;
            },
            set( note ) {
                this.$store.commit( 'PurchaseOrder/Detail/SET_CURRENT_PURCHASE_ORDER_CREDIT_CARD_NOTE', { note } );
            },
        },
    },
    methods: {
        closeModal() {
            this.$emit( 'close' );
        },
    },
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables.scss";
    .credit-card-modal {
        &__header {
            color: $white;
        }
        &__footer {
            margin: .25rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__text {
                text-align: left;
                font-size: 11px;
                width: 200px;
            }
        }
        &__body {
            margin: 0 1rem;
            text-align: left;
            &__input {
                margin: 1rem 0;
                &__header {
                    color: $dark-gray;
                    font-size: 14px;
                }
            }
        }
    }
</style>
