<template>
    <div id="loader-icon-wrapper">
        <ul class="book-icon-list">
            <li class="book_item first"></li>
            <li class="book_item second"></li>
            <li class="book_item third"></li>
            <li class="book_item fourth"></li>
            <li class="book_item fifth"></li>
            <li class="book_item sixth"></li>
        </ul>
        <div class="shelf">
            <p id="info-text">Working on it...</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoaderIcon',
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables.scss";
    @import "@/assets/sass/loadericon.scss";

    #loader-icon-wrapper {
        position: relative;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 300px;
    }

    .book-icon-list {
        margin: 0 auto;
        width: 300px;
        padding: 0;
    }

    .book_item {
        position: absolute;
        top: -120px;
        box-sizing: border-box;
        list-style: none;
        width: 40px;
        height: 120px;
        opacity: 0;
        background-color: $loader-book-item-bg;
        border: 5px solid $loader-book-item-border;
        transform-origin: bottom left;
        transform: translateX(300px);
        animation: travel 2500ms linear infinite;
    }
    .book_item.first {
        top: -140px;
        height: 140px;
    }
    .book_item.first:before, .book_item.first:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: $loader-book-item-first;
    }
    .book_item.first:after {
        top: initial;
        bottom: 10px;
    }
    .book_item.second:before, .book_item.second:after, .book_item.fifth:before, .book_item.fifth:after {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: 17.5px;
        border-top: 5px solid $loader-book-item-border;
        border-bottom: 5px solid $loader-book-item-border;
    }
    .book_item.second:after, .book_item.fifth:after {
        top: initial;
        bottom: 10px;
    }
    .book_item.third:before, .book_item.third:after {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 10px;
        left: 9px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 5px solid $loader-book-item-border;
    }
    .book_item.third:after {
        top: initial;
        bottom: 10px;
    }
    .book_item.fourth {
        top: -130px;
        height: 130px;
    }
    .book_item.fourth:before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 46px;
        left: 0;
        width: 100%;
        height: 17.5px;
        border-top: 5px solid $loader-book-item-border;
        border-bottom: 5px solid $loader-book-item-border;
    }
    .book_item.fifth {
        top: -100px;
        height: 100px;
    }
    .book_item.sixth {
        top: -140px;
        height: 140px;
    }
    .book_item.sixth:before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        bottom: 31px;
        left: 0px;
        width: 100%;
        height: 5px;
        background-color: $loader-book-item-first;
    }
    .book_item.sixth:after {
        box-sizing: border-box;
        content: '';
        position: absolute;
        bottom: 10px;
        left: 9px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 5px solid $loader-book-item-border;
    }
    .book_item:nth-child(2) {
        animation-delay: 416.6666666667ms;
    }
    .book_item:nth-child(3) {
        animation-delay: 833.3333333333ms;
    }
    .book_item:nth-child(4) {
        animation-delay: 1250ms;
    }
    .book_item:nth-child(5) {
        animation-delay: 1666.6666666667ms;
    }
    .book_item:nth-child(6) {
        animation-delay: 2083.3333333333ms;
    }

    .shelf {
        width: 300px;
        height: 5px;
        margin: 0 auto;
        background-color: $loader-shelf-bg;
        position: relative;
    }

    @-webkit-keyframes move {
        from {
            background-position-x: 0;
        }
        to {
            background-position-x: 10px;
        }
    }

    @keyframes move {
        from {
            background-position-x: 0;
        }
        to {
            background-position-x: 10px;
        }
    }

    @-webkit-keyframes travel {
        0% {
            opacity: 0;
            transform: translateX(300px) rotateZ(0deg) scaleY(1);
        }
        6.5% {
            transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
        }
        8.8% {
            transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
        }
        10% {
            opacity: 1;
            transform: translateX(270px) rotateZ(0deg);
        }
        17.6% {
            transform: translateX(247.2px) rotateZ(-30deg);
        }
        45% {
            transform: translateX(165px) rotateZ(-30deg);
        }
        49.5% {
            transform: translateX(151.5px) rotateZ(-45deg);
        }
        61.5% {
            transform: translateX(115.5px) rotateZ(-45deg);
        }
        67% {
            transform: translateX(99px) rotateZ(-60deg);
        }
        76% {
            transform: translateX(72px) rotateZ(-60deg);
        }
        83.5% {
            opacity: 1;
            transform: translateX(49.5px) rotateZ(-90deg);
        }
        90% {
            opacity: 0;
        }
        100% {
            opacity: 0;
            transform: translateX(0px) rotateZ(-90deg);
        }
    }

    @keyframes travel {
        0% {
            opacity: 0;
            transform: translateX(300px) rotateZ(0deg) scaleY(1);
        }
        6.5% {
            transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
        }
        8.8% {
            transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
        }
        10% {
            opacity: 1;
            transform: translateX(270px) rotateZ(0deg);
        }
        17.6% {
            transform: translateX(247.2px) rotateZ(-30deg);
        }
        45% {
            transform: translateX(165px) rotateZ(-30deg);
        }
        49.5% {
            transform: translateX(151.5px) rotateZ(-45deg);
        }
        61.5% {
            transform: translateX(115.5px) rotateZ(-45deg);
        }
        67% {
            transform: translateX(99px) rotateZ(-60deg);
        }
        76% {
            transform: translateX(72px) rotateZ(-60deg);
        }
        83.5% {
            opacity: 1;
            transform: translateX(49.5px) rotateZ(-90deg);
        }
        90% {
            opacity: 0;
        }
        100% {
            opacity: 0;
            transform: translateX(0px) rotateZ(-90deg);
        }
    }

    #info-text {
        padding-top: 30px;
        alignment: center;
    }
</style>
