<template>
    <v-btn
        outlined
        :disabled="disabled"
        @click="exportData()"
    >
        <v-icon>mdi-arrow-top-right</v-icon>
        Export
    </v-btn>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import CsvUtil from '@/utils/CsvUtil';

export default {
    name: 'ExportPurchaseOrderLines',
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
        } ),
    },
    methods: {
        exportData() {
            CsvUtil.create( {
                filename: `${ this.currentPurchaseOrder.number }_export`,
                headers: [
                    'Condition',
                    'EAN',
                    'Title Description',
                    'Quantity',
                    'Price',
                    'Expected Receive Date',
                    'EAN Delayed',
                    'Notes',
                ],
                data: this.currentPurchaseOrder.purchaseOrderItemDTOList.map( ( item ) => ( {
                    condition: item.condition,
                    productCode: item.productCode,
                    titleDescription: item.titleDescription,
                    requestedQuantity: item.requestedQuantity,
                    requestedPrice: item.requestedPrice,
                    expectedReceiveDate: item.expectedReceiveDate,
                    backordered: item.backordered,
                    note: item.note,
                } ) ),
            } );
        },
    },
};
</script>
