<template>
    <v-dialog
        v-model="showModal"
        class="modal-notify-only-status-can-change"
        maxWidth="800"
    >
        <v-card>
            <v-card-title class="primary white--text">
                <h3>Confirm Line Item Changes</h3>
                <v-spacer />
                <v-btn
                    icon
                    @click="closeModal()"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <p>This purchase order is in the status: <strong>{{ currentPurchaseOrder.status }}</strong></p>
                <p>Are you sure you want to save these purchase order item price/quantity changes?</p>
                <br/>
                <TableBaseFilterable
                    :filter.sync="table.filter"
                    :headers="table.headers"
                    :items="promptableLineItemChanges"
                    filterLabel="Filter Line Item Changes..."
                    noDataText="No line item changes"
                    hideAdd
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <!-- Dismiss -->
                <v-btn
                    outlined
                    @click="closeModal()"
                >
                    Dismiss
                </v-btn>
                <!-- Save -->
                <v-btn
                    color="primary"
                    @click="save()"
                >
                    {{ saveButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'ModalConfirmLineItemChanges',
    components: {
        TableBaseFilterable,
    },
    props: {
        saveButtonText: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            table: {
                filter: '',
                headers: [
                    {
                        text: 'Change Type',
                        value: 'type',
                    },
                    {
                        text: 'Original Value',
                        value: 'originalValue',
                        align: 'end',
                    },
                    {
                        text: 'New Value',
                        value: 'newValue',
                        align: 'end',
                    },
                    {
                        text: 'Condition',
                        value: 'condition',
                    },
                    {
                        text: 'EAN',
                        value: 'productCode',
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters( {
            referencePurchaseOrder: 'PurchaseOrder/Detail/referencePurchaseOrder',
            modifiedPurchaseOrderLineItems: 'PurchaseOrder/Detail/modifiedPurchaseOrderLineItems',
            promptableLineItemChanges: 'PurchaseOrder/Detail/promptableLineItemChanges',
        } ),
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
        } ),
        showModal: {
            get() {
                return this.value;
            },
            set( value ) {
                this.$emit( 'input', value );
            },
        },
    },
    methods: {
        closeModal() {
            this.showModal = false;
        },
        save() {
            this.$emit( 'save' );
        },
        saveAndNext() {
            this.$emit( 'saveAndNext' );
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/misc.scss";

#button-row {
    margin-top: -20px;
    display: flex;
    justify-content: flex-end;
}
.body-text {
    text-align: left;
    padding: 20px 20px 0;
    color: $confirm-text-fg;
    font-size: 14px;
}
.cancel-button {
    margin-top: 20px;
    color: $confirm-cancel-fg;
    border: solid 1px;
    height: unset;
    width: unset;
}
::v-deep .v-dialog > .v-card {
    .v-card__actions {
        padding-bottom: 1rem;
    }
}
</style>
